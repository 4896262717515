import owd from 'owd/owd';

export default class AppBar extends owd.Component {
	init() {
		this.bind();
	}

	bind() {
		this.on('click', '.bar-trigger', (e) => {
			e.preventDefault();
			this.$element.toggleClass('hide');
		});
	}

}

owd.registerComponent('app-bar', {
	component: AppBar
});
