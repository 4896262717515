// Source: https://developer.mozilla.org/en/Core_JavaScript_1.5_Reference/Global_Objects/Math/random


export default {
	/**
	* Returns a random number between min (inclusive) and max (exclusive)
	*/
	arbitrary: (min, max) => {
		return Math.random() * (max - min) + min;
	},

	/**
	* Returns a random integer between min (inclusive) and max (inclusive)
	* Using Math.round() will give you a non-uniform distribution!
	*/
	int: (min, max) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}
};
