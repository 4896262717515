import Component from 'component';

const components = {};
// const services = {};

class App {
	constructor() {
		this.dom = {
			$body: $('body'),
			$html: $('html'),
			$window: $(window)
		};

		console.log('Bootstrapping owd.');
		console.log('Components registered: ', components);

		this.dom.$body.on('owd.tick', (e) => {
			this.tick($(e.currentTarget));
		});
	}

	run() {
		this.tick(this.dom.$body);
		this.dom.$window.trigger('domready');
	}

	tick($context) {
		console.log('tick in context of: ', $context[0]);

		for (let selector in components) {
			this.initializeComponent(selector, $context.find('[data-' + selector + ']'));
		}
	}

	initializeComponent(selector, $context) {
		$context.each((index, element) => {
			if (element['owd-component-' + selector] === undefined) {
				element['owd-component-' + selector] = new components[selector].component($(element), {}, this);
			}
		});
	}
}

export default {
	Component: Component,
	App: App,

	registerComponent: (selector, meta) => {
		if (components.selector !== undefined) {
			throw 'Component for selector "' + selector + '" has been already registered.';
		}

		components[selector] = meta;
	}

	// registerService: (slug, meta) => {
	// 	if (components.selector !== undefined) {
	// 		throw 'Component for selector "' + selector + '" has been already registered.';
	// 	}
	//
	// 	components[selector] = meta;
	// }
};
