import owd from 'owd/owd';
import SlotRandomizer from 'app/utils/slot-randomizer';

Array.prototype.shuffle = function(){
  return this.sort(() => 0.5 - Math.random());
};

export default class SlotMachine extends owd.Component {
	init() {
		this.hasStarted = false;
		this.isAnimating = false;
		this.prevPercent = [0, 0, 0];
		this.cfg = window.cfg.slotMachine;
		this.buildHTML();
		this.bind();
		this.randomizer = new SlotRandomizer(window.cfg.slotMachine);

		window.slotMachineTrigger = () => {
			this.trigger();
		};
	}

	bind() {
		this.on('click', '[data-slot-trigger]', (e) => {
			e.preventDefault();
			this.trigger();
		});

		this.on('click', '[data-show-spotify]', (e) => {
			e.preventDefault();
			this.showSpotifyScreen($(e.target).data('show-spotify'));
		});

		$('#mobile-button').on('touchstart', () => {
			$('#mobile-button-active').addClass('touch-hover');
		}).on('touchend', () => {
			$('#mobile-button-active').removeClass('touch-hover');
		});

		this.on('load', this.dom.$window, () => {
			this.dom.$body.addClass('loaded');
			setTimeout(() => {
				this.$element.find('.screen-start').addClass('active');
			}, 100);
			this.blinkEnable();
		});
	}

	trigger() {
		if (!this.isAnimating) {
			if (this.hasStarted) {
				this.hideLooseWinScreen();
			} else {
				this.hideStartScreen();
			}

			this.hasStarted = true;

			this.blinkDisable();

			this.play();
		}
	}

	hideStartScreen() {
		this.$element.find('.screen-start').hide();
		this.$element.find('.screen-machine').fadeIn();
		this.$element.find('.screen').removeClass('active');
		this.$element.find('#Lights').attr('data-animation', 'animationBlinking');
		this.$element.find('#LightsMobile').attr('data-animation', 'animationBlinking');
	}

	showLooseScreen() {
		this.$element.find('.screen-machine').hide();
		this.$element.find('.screen-unlucky').fadeIn();
		this.$element.find('.screen').removeClass('active');
		setTimeout(() => {
			this.$element.find('.screen-unlucky').addClass('active');
		}, 100);
		this.$element.find('#Lights').attr('data-animation', 'animationAround');
		this.$element.find('#LightsMobile').attr('data-animation', 'animationAround');
		this.blinkEnable();
	}

	hideLooseWinScreen() {
		this.$element.find('.screen-lucky').hide();
		this.$element.find('.screen-unlucky').hide();
		this.$element.find('.screen-spotify').hide();
		this.$element.find('.screen-machine').fadeIn();
		this.$element.find('.screen').removeClass('active');
		this.$element.find('#Lights').attr('data-animation', 'animationBlinking');
		this.$element.find('#LightsMobile').attr('data-animation', 'animationBlinking');
		this.$element.find('.screen-spotify iframe.spotify-playlist-iframe').attr('src', 'about:blank');
	}

	showGifScreen(gif) {
		const deferred = $.Deferred();

		this.$element.find('.screen-machine').hide();
		this.$element.find('.screen-gif').css({
			backgroundImage: `url(${this.imagePath(gif.src, 'gif')})`
		});
		this.$element.find('.screen-gif').fadeIn();
		this.$element.find('.screen').removeClass('active');
		this.$element.find('.screen-gif').addClass('active');

		setTimeout(() => {
			this.$element.find('.screen-gif').css({
				backgroundImage: 'none'
			});
			deferred.resolve();
		}, gif.duration);

		return deferred.promise();
	}

	showWinScreen(emoji) {
		this.$element.find('.screen-gif').hide();
		this.$element.find('.screen-lucky[data-emoji="' + emoji + '"]').fadeIn();
		this.$element.find('.screen').removeClass('active');
		setTimeout(() => {
			this.$element.find('.screen-lucky[data-emoji="' + emoji + '"]').addClass('active');
		}, 100);
		this.$element.find('#Lights').attr('data-animation', 'animationAround');
		this.$element.find('#LightsMobile').attr('data-animation', 'animationAround');
		this.blinkEnable();
	}

	showSpotifyScreen(iframeSrc) {
		this.$element.find('.screen-lucky').hide();
		this.$element.find('.screen-spotify iframe.spotify-playlist-iframe').attr('src', iframeSrc);
		this.$element.find('.screen-spotify').fadeIn();
	}

	blinkEnable() {
		this.$element.addClass('ready-to-spin');
	}

	blinkDisable() {
		this.$element.removeClass('ready-to-spin');
	}

	preloadGif(url) {
		const img = new Image();
		img.src = url;
	}

	imagePath(relativeSrc, type) {
		if (type === 'emoji') {
			return this.cfg.basePath + this.cfg.emojisRelPath + relativeSrc;
		} else if (type === 'gif') {
			return this.cfg.basePath + this.cfg.gifsRelPath + relativeSrc;
		}

		return this.cfg.basePath + relativeSrc;
	}

	buildHTML() {
		for (let s=1; s<=3; s++) {
			let items = [];

			/* jshint ignore:start */
			this.cfg.emojis.forEach((emoji) => {
				items.push(`<div class="slot-item"><div class="slot-item-inner"><img src="${this.imagePath(emoji, 'emoji')}" data-emoji="${emoji}"></div></div>`);
			});
			/* jshint ignore:end */

			items.shuffle();

			for (let i=0; i<3; i++) {
				items.push(items[i]);
			}

			let slotHTML = [
				'<div class="slot-animation-wrapper">',
				items.join("\n"),
				'</div>'
			].join("\n");

			this.$element.find('[data-slot="' + s + '"]').html(slotHTML);
		}

	}

	play() {
		if (this.isAnimating) {
			return;
		}

		this.isAnimating = true;

		const percent = this.cfg.emojis.length / (this.cfg.emojis.length + 3) * 100;
		const result = this.randomizer.randomize();

		const tl = new TimelineMax({
			onComplete: () => {

				setTimeout(() => {
					$('.bg-animation').removeAttr('data-animation');

					if (result.isWinning) {
						this.showGifScreen(result.gif).then(() => {
							this.showWinScreen(this.cfg.emojis[result.combination[0]]);
							this.isAnimating = false;
						});
						$('.bg-animation').attr('data-animation', result.bgAnimation);
					} else {
						let matches = 0;

						if (result.combination[0] == result.combination[1] ||
							result.combination[1] == result.combination[2] ||
							result.combination[0] == result.combination[2]) {

							matches = 2;
						}

						this.$element.find('[data-matches]').html(matches);
						this.showLooseScreen();
						this.isAnimating = false;
					}
				}, 800);
			}
		});

		if (result.isWinning) {
			this.preloadGif(this.imagePath(result.gif.src, 'gif'));
		}

		const duration = 1.2;

		let finishPercent = [];

		result.combination.forEach((combinationItem, combinationIndex) => {
			let $el = this.$element.find(`[data-slot="${combinationIndex + 1}"] [data-emoji="${this.cfg.emojis[combinationItem]}"]`);

			if ($el.eq(0).parents('.slot-item').index() < 2) {
				finishPercent.push(
					($el.eq($el.length - 1).parents('.slot-item').index() - 1) / this.cfg.emojis.length * percent
				);
			} else {
				finishPercent.push(
					($el.eq(0).parents('.slot-item').index() - 1) / this.cfg.emojis.length * percent
				);
			}
		});

		let startPercent = this.prevPercent;

		if (startPercent[0] == finishPercent[0]) {
			startPercent[0] = 0;
		}

		if (startPercent[1] == finishPercent[1]) {
			startPercent[1] = 0;
		}

		if (startPercent[2] == finishPercent[2]) {
			startPercent[2] = 0;
		}

		tl.fromTo(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), duration * 0.5, { y: `-${startPercent[0] + 1.5}%` }, { y: `-${startPercent[0]}%`, ease: Back.easeOut, delay: 0.1 }, 'prepare');
		tl.fromTo(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), duration * 0.55, { y: `-${startPercent[1] + 1}%` }, { y: `-${startPercent[1]}%`, ease: Back.easeOut, delay: 0.05 }, 'prepare');
		tl.fromTo(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), duration * 0.6, { y: `-${startPercent[2] + 0.5}%` }, { y: `-${startPercent[2]}%`, ease: Back.easeOut, delay: 0 }, 'prepare');

		tl.to(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Quad.easeIn, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), { y: `0%` });
		}, delay: 0.5 }, 'start');
		tl.to(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Quad.easeIn, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), { y: `0%` });
		}, delay: 0.5 }, 'start');
		tl.to(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Quad.easeIn, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), { y: `0%` });
		}, delay: 0.5 }, 'start');

		tl.to(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Linear.easeNone, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), { y: `0%` });
		} }, 'mid');
		tl.to(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Linear.easeNone, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), { y: `0%` });
		} }, 'mid');
		tl.to(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Linear.easeNone, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), { y: `0%` });
		} }, 'mid');

		tl.to(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), duration * 0.5, { y: `-${finishPercent[0]}%`, ease: Back.easeOut, easeParams: [0.4] }, 'end');
		tl.to(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), duration * 0.75, { y: `-${finishPercent[1]}%`, ease: Back.easeOut, easeParams: [0.4] }, 'end');
		tl.to(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), duration * 1, { y: `-${finishPercent[2]}%`, ease: Back.easeOut, easeParams: [0.4] }, 'end');

		$('.bg-animation').attr('data-animation', 'default');

		this.prevPercent = finishPercent;
	}

	destroy() {
		super.destroy();

		$('#mobile-button').off('touchstart');
		$('#mobile-button').off('touchend');
	}
}

owd.registerComponent('slot-machine', {
	component: SlotMachine
});
