import random from 'owd/utils/random';

export default class SlotRandomizer {
	constructor(cfg) {
		this.cfg = cfg;
		this.resetCycle();
		this.randomizeLimit();
	}

	randomizeLimit() {
		this.limit = random.int(1, 3);
	}

	resetCycle() {
		this.cycle = 1;
	}

	incrementCycle() {
		this.cycle++;
	}

	randomize() {
		const result = {};

		if (this.cycle === this.limit) {
			result.isWinning = true;

			let randomWinning = random.int(0, this.cfg.winning.length - 1);
			let winningKey = this.cfg.emojis.indexOf(this.cfg.winning[randomWinning]);

			result.combination = [
				winningKey,
				winningKey,
				winningKey
			];

			result.gif = this.cfg.gifs[this.cfg.winning[randomWinning]];
			result.bgAnimation = this.cfg.animations[this.cfg.winning[randomWinning]];

			this.resetCycle();
			this.randomizeLimit();
		} else {
			result.isWinning = false;

			do {
				result.combination = [
					random.int(0, this.cfg.emojis.length - 3),
					random.int(0, this.cfg.emojis.length - 3),
					random.int(0, this.cfg.emojis.length - 3)
				];
			} while(this.isWinning(result.combination));

			this.incrementCycle();
		}

		return result;
	}

	isWinning(combination) {
		return combination[0] == combination[1] &&
			   combination[1] == combination[2];
	}


}
