import owd from 'owd/owd';

export default class KeepProportion extends owd.Component {
	init() {
		this.bind();
		this.check();
	}

	bind() {
		this.on('domready load resize', this.dom.$window, () => {
			this.check();
		});
	}

	check() {
		const windowWidth = this.dom.$window.width();
		const windowHeight = Math.min(window.innerHeight, this.dom.$window.height());
		const windowProportion = windowWidth / windowHeight;
		let desiredProportion;
		let height;
		let $svg;

		this.$element.css({
			height: windowHeight
		});

		this.$element.parent().css({
			height: windowHeight
		});

		if (window.matchMedia('(max-width: 767px) and (orientation: landscape)').matches) {
			desiredProportion = 460 / 300;
			$svg = this.$element.find('svg.machine-mobile');
			height = '';
		} else {
			desiredProportion = 391.6 / 313.8;
			$svg = this.$element.find('svg.machine-desktop');
			height = Math.min(1014, windowHeight);
		}

		if ($svg.outerHeight() < 330) {
			$('html').addClass('vsmall');
		} else {
			$('html').removeClass('vsmall');
		}

		if (windowProportion > desiredProportion) {
			if (this.isHorizontal !== true) {
				this.$element.removeClass('is-vertical');
				this.$element.addClass('is-horizontal');
				this.isHorizontal = true;
			}

			this.$element.find('.machine').removeAttr('style');
			this.$element.find('.machine').css({
				height: height,
				width: $svg.outerWidth(),
				left: (Math.min(1185, windowWidth) - $svg.outerWidth()) / 2
			});
		} else {
			if (this.isHorizontal !== false) {
				this.$element.addClass('is-vertical');
				this.$element.removeClass('is-horizontal');
				this.isHorizontal = false;
			}

			this.$element.find('.machine').css({
				height: '',
				width: '',
				left: ''
			});
		}
	}
}

owd.registerComponent('keep-proportion', {
	component: KeepProportion
});
