
export default class Component {
	constructor($element, scope, app) {
		this.app = app;
		this.dom = $.extend({}, this.app.dom);
		this.scope = scope || {};
		this.isDestroyed = false;
		this.$element = $element;
		this.listeners = [];

		this.$element.one('owd.destroy', this.destroy);

		this.init();
	}

	init() {

	}

	tick() {
		
	}

	apply(fn) {
		if (fn !== undefined) {
			fn();
		}

		this.$element.trigger('owd.tick');
		this.tick();
	}

	// on(events, callback) {
	// on(events, selector, callback) {
	// on(events, $target, callback) {
	// on(events, $target, selector, callback) {
	on(events, $target, selector, callback) {

		if (callback === undefined && selector === undefined) {
			callback = $target;
			$target = this.$element;
		} else if (callback === undefined && typeof($target) == 'string') {
			callback = selector;
			selector = $target;
			$target = this.$element;
		} else if (typeof(selector) == 'function') {
			callback = selector;
			selector = undefined;
		}

		// console.log('events', events, '$target', $target, 'selector', selector, 'callback', callback);

		this.listeners.push({
			events: events,
			$target: $target,
			selector: selector,
			callback: callback
		});

		if (selector !== undefined) {
			$target.on(events, selector, callback);
		} else {
			$target.on(events, callback);
		}
	}

	off(events, $target, selector, callback) {
		if (callback === undefined && selector === undefined) {
			callback = $target;
			$target = this.$element;
		} else if (callback === undefined && typeof($target) == 'string') {
			callback = selector;
			selector = $target;
			$target = this.$element;
		} else if (typeof(selector) == 'function') {
			callback = selector;
			selector = undefined;
		}

		this.listeners.forEach((item, index) => {
			if (item.events === events && item.$target === $target &&
				item.selector === selector && item.callback === callback) {

				this.listeners.splice(index, 1);
			}
		});

		if (selector !== undefined) {
			$target.off(events, selector, callback);
		} else {
			$target.off(events, callback);
		}
	}

	destroy() {
		if (!this.isDestroyed) {
			this.isDestroyed = true;

			this.listeners.forEach((item, index) => {
				if (item.selector !== undefined) {
					item.$target.off(item.events, item.selector, item.callback);
				} else {
					item.$target.off(item.events, item.callback);
				}

				this.listeners.splice(index, 1);
			});

			this.$element.children().trigger('owd.destroy', this, this.$element);
		}
	}
}
