class Component {
	constructor($element, scope, app) {
		this.app = app;
		this.dom = $.extend({}, this.app.dom);
		this.scope = scope || {};
		this.isDestroyed = false;
		this.$element = $element;
		this.listeners = [];

		this.$element.one('owd.destroy', this.destroy);

		this.init();
	}

	init() {

	}

	tick() {
		
	}

	apply(fn) {
		if (fn !== undefined) {
			fn();
		}

		this.$element.trigger('owd.tick');
		this.tick();
	}

	// on(events, callback) {
	// on(events, selector, callback) {
	// on(events, $target, callback) {
	// on(events, $target, selector, callback) {
	on(events, $target, selector, callback) {

		if (callback === undefined && selector === undefined) {
			callback = $target;
			$target = this.$element;
		} else if (callback === undefined && typeof($target) == 'string') {
			callback = selector;
			selector = $target;
			$target = this.$element;
		} else if (typeof(selector) == 'function') {
			callback = selector;
			selector = undefined;
		}

		// console.log('events', events, '$target', $target, 'selector', selector, 'callback', callback);

		this.listeners.push({
			events: events,
			$target: $target,
			selector: selector,
			callback: callback
		});

		if (selector !== undefined) {
			$target.on(events, selector, callback);
		} else {
			$target.on(events, callback);
		}
	}

	off(events, $target, selector, callback) {
		if (callback === undefined && selector === undefined) {
			callback = $target;
			$target = this.$element;
		} else if (callback === undefined && typeof($target) == 'string') {
			callback = selector;
			selector = $target;
			$target = this.$element;
		} else if (typeof(selector) == 'function') {
			callback = selector;
			selector = undefined;
		}

		this.listeners.forEach((item, index) => {
			if (item.events === events && item.$target === $target &&
				item.selector === selector && item.callback === callback) {

				this.listeners.splice(index, 1);
			}
		});

		if (selector !== undefined) {
			$target.off(events, selector, callback);
		} else {
			$target.off(events, callback);
		}
	}

	destroy() {
		if (!this.isDestroyed) {
			this.isDestroyed = true;

			this.listeners.forEach((item, index) => {
				if (item.selector !== undefined) {
					item.$target.off(item.events, item.selector, item.callback);
				} else {
					item.$target.off(item.events, item.callback);
				}

				this.listeners.splice(index, 1);
			});

			this.$element.children().trigger('owd.destroy', this, this.$element);
		}
	}
}

const components = {};
// const services = {};

class App {
	constructor() {
		this.dom = {
			$body: $('body'),
			$html: $('html'),
			$window: $(window)
		};

		console.log('Bootstrapping owd.');
		console.log('Components registered: ', components);

		this.dom.$body.on('owd.tick', (e) => {
			this.tick($(e.currentTarget));
		});
	}

	run() {
		this.tick(this.dom.$body);
		this.dom.$window.trigger('domready');
	}

	tick($context) {
		console.log('tick in context of: ', $context[0]);

		for (let selector in components) {
			this.initializeComponent(selector, $context.find('[data-' + selector + ']'));
		}
	}

	initializeComponent(selector, $context) {
		$context.each((index, element) => {
			if (element['owd-component-' + selector] === undefined) {
				element['owd-component-' + selector] = new components[selector].component($(element), {}, this);
			}
		});
	}
}

var owd = {
	Component: Component,
	App: App,

	registerComponent: (selector, meta) => {
		if (components.selector !== undefined) {
			throw 'Component for selector "' + selector + '" has been already registered.';
		}

		components[selector] = meta;
	}

	// registerService: (slug, meta) => {
	// 	if (components.selector !== undefined) {
	// 		throw 'Component for selector "' + selector + '" has been already registered.';
	// 	}
	//
	// 	components[selector] = meta;
	// }
};

// Source: https://developer.mozilla.org/en/Core_JavaScript_1.5_Reference/Global_Objects/Math/random


var random = {
	/**
	* Returns a random number between min (inclusive) and max (exclusive)
	*/
	arbitrary: (min, max) => {
		return Math.random() * (max - min) + min;
	},

	/**
	* Returns a random integer between min (inclusive) and max (inclusive)
	* Using Math.round() will give you a non-uniform distribution!
	*/
	int: (min, max) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}
};

class SlotRandomizer {
	constructor(cfg) {
		this.cfg = cfg;
		this.resetCycle();
		this.randomizeLimit();
	}

	randomizeLimit() {
		this.limit = random.int(1, 3);
	}

	resetCycle() {
		this.cycle = 1;
	}

	incrementCycle() {
		this.cycle++;
	}

	randomize() {
		const result = {};

		if (this.cycle === this.limit) {
			result.isWinning = true;

			let randomWinning = random.int(0, this.cfg.winning.length - 1);
			let winningKey = this.cfg.emojis.indexOf(this.cfg.winning[randomWinning]);

			result.combination = [
				winningKey,
				winningKey,
				winningKey
			];

			result.gif = this.cfg.gifs[this.cfg.winning[randomWinning]];
			result.bgAnimation = this.cfg.animations[this.cfg.winning[randomWinning]];

			this.resetCycle();
			this.randomizeLimit();
		} else {
			result.isWinning = false;

			do {
				result.combination = [
					random.int(0, this.cfg.emojis.length - 3),
					random.int(0, this.cfg.emojis.length - 3),
					random.int(0, this.cfg.emojis.length - 3)
				];
			} while(this.isWinning(result.combination));

			this.incrementCycle();
		}

		return result;
	}

	isWinning(combination) {
		return combination[0] == combination[1] &&
			   combination[1] == combination[2];
	}


}

Array.prototype.shuffle = function(){
  return this.sort(() => 0.5 - Math.random());
};

class SlotMachine extends owd.Component {
	init() {
		this.hasStarted = false;
		this.isAnimating = false;
		this.prevPercent = [0, 0, 0];
		this.cfg = window.cfg.slotMachine;
		this.buildHTML();
		this.bind();
		this.randomizer = new SlotRandomizer(window.cfg.slotMachine);

		window.slotMachineTrigger = () => {
			this.trigger();
		};
	}

	bind() {
		this.on('click', '[data-slot-trigger]', (e) => {
			e.preventDefault();
			this.trigger();
		});

		this.on('click', '[data-show-spotify]', (e) => {
			e.preventDefault();
			this.showSpotifyScreen($(e.target).data('show-spotify'));
		});

		$('#mobile-button').on('touchstart', () => {
			$('#mobile-button-active').addClass('touch-hover');
		}).on('touchend', () => {
			$('#mobile-button-active').removeClass('touch-hover');
		});

		this.on('load', this.dom.$window, () => {
			this.dom.$body.addClass('loaded');
			setTimeout(() => {
				this.$element.find('.screen-start').addClass('active');
			}, 100);
			this.blinkEnable();
		});
	}

	trigger() {
		if (!this.isAnimating) {
			if (this.hasStarted) {
				this.hideLooseWinScreen();
			} else {
				this.hideStartScreen();
			}

			this.hasStarted = true;

			this.blinkDisable();

			this.play();
		}
	}

	hideStartScreen() {
		this.$element.find('.screen-start').hide();
		this.$element.find('.screen-machine').fadeIn();
		this.$element.find('.screen').removeClass('active');
		this.$element.find('#Lights').attr('data-animation', 'animationBlinking');
		this.$element.find('#LightsMobile').attr('data-animation', 'animationBlinking');
	}

	showLooseScreen() {
		this.$element.find('.screen-machine').hide();
		this.$element.find('.screen-unlucky').fadeIn();
		this.$element.find('.screen').removeClass('active');
		setTimeout(() => {
			this.$element.find('.screen-unlucky').addClass('active');
		}, 100);
		this.$element.find('#Lights').attr('data-animation', 'animationAround');
		this.$element.find('#LightsMobile').attr('data-animation', 'animationAround');
		this.blinkEnable();
	}

	hideLooseWinScreen() {
		this.$element.find('.screen-lucky').hide();
		this.$element.find('.screen-unlucky').hide();
		this.$element.find('.screen-spotify').hide();
		this.$element.find('.screen-machine').fadeIn();
		this.$element.find('.screen').removeClass('active');
		this.$element.find('#Lights').attr('data-animation', 'animationBlinking');
		this.$element.find('#LightsMobile').attr('data-animation', 'animationBlinking');
		this.$element.find('.screen-spotify iframe.spotify-playlist-iframe').attr('src', 'about:blank');
	}

	showGifScreen(gif) {
		const deferred = $.Deferred();

		this.$element.find('.screen-machine').hide();
		this.$element.find('.screen-gif').css({
			backgroundImage: `url(${this.imagePath(gif.src, 'gif')})`
		});
		this.$element.find('.screen-gif').fadeIn();
		this.$element.find('.screen').removeClass('active');
		this.$element.find('.screen-gif').addClass('active');

		setTimeout(() => {
			this.$element.find('.screen-gif').css({
				backgroundImage: 'none'
			});
			deferred.resolve();
		}, gif.duration);

		return deferred.promise();
	}

	showWinScreen(emoji) {
		this.$element.find('.screen-gif').hide();
		this.$element.find('.screen-lucky[data-emoji="' + emoji + '"]').fadeIn();
		this.$element.find('.screen').removeClass('active');
		setTimeout(() => {
			this.$element.find('.screen-lucky[data-emoji="' + emoji + '"]').addClass('active');
		}, 100);
		this.$element.find('#Lights').attr('data-animation', 'animationAround');
		this.$element.find('#LightsMobile').attr('data-animation', 'animationAround');
		this.blinkEnable();
	}

	showSpotifyScreen(iframeSrc) {
		this.$element.find('.screen-lucky').hide();
		this.$element.find('.screen-spotify iframe.spotify-playlist-iframe').attr('src', iframeSrc);
		this.$element.find('.screen-spotify').fadeIn();
	}

	blinkEnable() {
		this.$element.addClass('ready-to-spin');
	}

	blinkDisable() {
		this.$element.removeClass('ready-to-spin');
	}

	preloadGif(url) {
		const img = new Image();
		img.src = url;
	}

	imagePath(relativeSrc, type) {
		if (type === 'emoji') {
			return this.cfg.basePath + this.cfg.emojisRelPath + relativeSrc;
		} else if (type === 'gif') {
			return this.cfg.basePath + this.cfg.gifsRelPath + relativeSrc;
		}

		return this.cfg.basePath + relativeSrc;
	}

	buildHTML() {
		for (let s=1; s<=3; s++) {
			let items = [];

			/* jshint ignore:start */
			this.cfg.emojis.forEach((emoji) => {
				items.push(`<div class="slot-item"><div class="slot-item-inner"><img src="${this.imagePath(emoji, 'emoji')}" data-emoji="${emoji}"></div></div>`);
			});
			/* jshint ignore:end */

			items.shuffle();

			for (let i=0; i<3; i++) {
				items.push(items[i]);
			}

			let slotHTML = [
				'<div class="slot-animation-wrapper">',
				items.join("\n"),
				'</div>'
			].join("\n");

			this.$element.find('[data-slot="' + s + '"]').html(slotHTML);
		}

	}

	play() {
		if (this.isAnimating) {
			return;
		}

		this.isAnimating = true;

		const percent = this.cfg.emojis.length / (this.cfg.emojis.length + 3) * 100;
		const result = this.randomizer.randomize();

		const tl = new TimelineMax({
			onComplete: () => {

				setTimeout(() => {
					$('.bg-animation').removeAttr('data-animation');

					if (result.isWinning) {
						this.showGifScreen(result.gif).then(() => {
							this.showWinScreen(this.cfg.emojis[result.combination[0]]);
							this.isAnimating = false;
						});
						$('.bg-animation').attr('data-animation', result.bgAnimation);
					} else {
						let matches = 0;

						if (result.combination[0] == result.combination[1] ||
							result.combination[1] == result.combination[2] ||
							result.combination[0] == result.combination[2]) {

							matches = 2;
						}

						this.$element.find('[data-matches]').html(matches);
						this.showLooseScreen();
						this.isAnimating = false;
					}
				}, 800);
			}
		});

		if (result.isWinning) {
			this.preloadGif(this.imagePath(result.gif.src, 'gif'));
		}

		const duration = 1.2;

		let finishPercent = [];

		result.combination.forEach((combinationItem, combinationIndex) => {
			let $el = this.$element.find(`[data-slot="${combinationIndex + 1}"] [data-emoji="${this.cfg.emojis[combinationItem]}"]`);

			if ($el.eq(0).parents('.slot-item').index() < 2) {
				finishPercent.push(
					($el.eq($el.length - 1).parents('.slot-item').index() - 1) / this.cfg.emojis.length * percent
				);
			} else {
				finishPercent.push(
					($el.eq(0).parents('.slot-item').index() - 1) / this.cfg.emojis.length * percent
				);
			}
		});

		let startPercent = this.prevPercent;

		if (startPercent[0] == finishPercent[0]) {
			startPercent[0] = 0;
		}

		if (startPercent[1] == finishPercent[1]) {
			startPercent[1] = 0;
		}

		if (startPercent[2] == finishPercent[2]) {
			startPercent[2] = 0;
		}

		tl.fromTo(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), duration * 0.5, { y: `-${startPercent[0] + 1.5}%` }, { y: `-${startPercent[0]}%`, ease: Back.easeOut, delay: 0.1 }, 'prepare');
		tl.fromTo(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), duration * 0.55, { y: `-${startPercent[1] + 1}%` }, { y: `-${startPercent[1]}%`, ease: Back.easeOut, delay: 0.05 }, 'prepare');
		tl.fromTo(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), duration * 0.6, { y: `-${startPercent[2] + 0.5}%` }, { y: `-${startPercent[2]}%`, ease: Back.easeOut, delay: 0 }, 'prepare');

		tl.to(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Quad.easeIn, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), { y: `0%` });
		}, delay: 0.5 }, 'start');
		tl.to(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Quad.easeIn, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), { y: `0%` });
		}, delay: 0.5 }, 'start');
		tl.to(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Quad.easeIn, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), { y: `0%` });
		}, delay: 0.5 }, 'start');

		tl.to(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Linear.easeNone, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), { y: `0%` });
		} }, 'mid');
		tl.to(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Linear.easeNone, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), { y: `0%` });
		} }, 'mid');
		tl.to(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), duration, { y: `-${percent}%`, ease: Linear.easeNone, onComplete: () => {
			TweenMax.set(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), { y: `0%` });
		} }, 'mid');

		tl.to(this.$element.find('[data-slot="1"] .slot-animation-wrapper'), duration * 0.5, { y: `-${finishPercent[0]}%`, ease: Back.easeOut, easeParams: [0.4] }, 'end');
		tl.to(this.$element.find('[data-slot="2"] .slot-animation-wrapper'), duration * 0.75, { y: `-${finishPercent[1]}%`, ease: Back.easeOut, easeParams: [0.4] }, 'end');
		tl.to(this.$element.find('[data-slot="3"] .slot-animation-wrapper'), duration * 1, { y: `-${finishPercent[2]}%`, ease: Back.easeOut, easeParams: [0.4] }, 'end');

		$('.bg-animation').attr('data-animation', 'default');

		this.prevPercent = finishPercent;
	}

	destroy() {
		super.destroy();

		$('#mobile-button').off('touchstart');
		$('#mobile-button').off('touchend');
	}
}

owd.registerComponent('slot-machine', {
	component: SlotMachine
});

class KeepProportion extends owd.Component {
	init() {
		this.bind();
		this.check();
	}

	bind() {
		this.on('domready load resize', this.dom.$window, () => {
			this.check();
		});
	}

	check() {
		const windowWidth = this.dom.$window.width();
		const windowHeight = Math.min(window.innerHeight, this.dom.$window.height());
		const windowProportion = windowWidth / windowHeight;
		let desiredProportion;
		let height;
		let $svg;

		this.$element.css({
			height: windowHeight
		});

		this.$element.parent().css({
			height: windowHeight
		});

		if (window.matchMedia('(max-width: 767px) and (orientation: landscape)').matches) {
			desiredProportion = 460 / 300;
			$svg = this.$element.find('svg.machine-mobile');
			height = '';
		} else {
			desiredProportion = 391.6 / 313.8;
			$svg = this.$element.find('svg.machine-desktop');
			height = Math.min(1014, windowHeight);
		}

		if ($svg.outerHeight() < 330) {
			$('html').addClass('vsmall');
		} else {
			$('html').removeClass('vsmall');
		}

		if (windowProportion > desiredProportion) {
			if (this.isHorizontal !== true) {
				this.$element.removeClass('is-vertical');
				this.$element.addClass('is-horizontal');
				this.isHorizontal = true;
			}

			this.$element.find('.machine').removeAttr('style');
			this.$element.find('.machine').css({
				height: height,
				width: $svg.outerWidth(),
				left: (Math.min(1185, windowWidth) - $svg.outerWidth()) / 2
			});
		} else {
			if (this.isHorizontal !== false) {
				this.$element.addClass('is-vertical');
				this.$element.removeClass('is-horizontal');
				this.isHorizontal = false;
			}

			this.$element.find('.machine').css({
				height: '',
				width: '',
				left: ''
			});
		}
	}
}

owd.registerComponent('keep-proportion', {
	component: KeepProportion
});

class AppBar extends owd.Component {
	init() {
		this.bind();
	}

	bind() {
		this.on('click', '.bar-trigger', (e) => {
			e.preventDefault();
			this.$element.toggleClass('hide');
		});
	}

}

owd.registerComponent('app-bar', {
	component: AppBar
});

const app = new owd.App();

app.run();
